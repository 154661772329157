export default {
  masterChef: {
    80001: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    137: '0xcf5bf6a8e6b3572aa9c0915a0c6c69a17de6edcc',
  },
  multiCall: {
    137: '0x3EA13B3AA01317D550776f772fD479170a1BD1da',
    80001: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
  },
}
